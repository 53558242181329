<template>
	<w-dialog v-model="dialog" :max-width="$vuetify.breakpoint.mdAndUp ? '60vw' : '90vw'" :title="$t('quickfolders.create')" @close="closeDialog()">
		<v-form v-model="isValid">
			<v-subheader v-text="$t('step', { num: 1 })" />
			<v-flex v-t="'quickfolders.info_settings'" />
			<v-layout style="max-height:40vh">
				<v-flex scroll-y style="min-height:150px">
					<FolderSelector required return-object :value="selectedFolder" :virtual-folders="false" @input="updateSelection($event)" />
				</v-flex>
			</v-layout>
			<v-subheader v-text="$t('step', { num: 2 })" />
			<w-text-input v-model="quickfolder.name" :label="$t('quickfolders.quickfolder_name')" maxlength="191" required />
		</v-form>
		<template v-slot:actions>
			<w-flex text-xs-right>
				<w-btn-save :disabled="!isValid" :loading="loading" @click="createQuickFolder" />
			</w-flex>
		</template>
	</w-dialog>
</template>

<script>
import QuickFoldersModuleGuard from '@/mixins/ModulesGuards/QuickFolders/QuickFoldersModuleGuard'

export default {
	name: 'QuickFoldersCreation',
	components: {
		FolderSelector: () => ({
			component: import('@/components/Documents/Forms/FolderSelector')
		})
	},
	mixins: [QuickFoldersModuleGuard],
	props: {
		company: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			dialog: true,
			isValid: false,
			loading: false,
			quickfolder: {},
			selectedFolder: null
		}
	},
	methods: {
		closeDialog: function () {
			this.dialog = false
			this.$emit('close')
		},
		createQuickFolder: function () {
			this.loading = true
			this.service
				.create(this.company.id, this.quickfolder)
				.then(res => {
					this.$emit('add', res)
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('quickfolders.actions.created'))
					this.closeDialog()
				})
				.finally(() => {
					this.loading = false
				})
		},
		selectFolder: function (folder) {
			this.quickfolder = {
				folder_id: folder.id,
				name: folder.name
			}
		},
		updateSelection: function (folder) {
			this.selectedFolder = folder
			this.quickfolder.folder_id = folder.id
			this.quickfolder.folder_name = folder.name
			this.quickfolder.name = folder.name
			this.$mount()
		}
	}
}
</script>
